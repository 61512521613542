import React, { useState } from "react"
import { useForm, useField } from "react-form"
import emailValidator from "email-validator"
import FileUpload from "components/contact/file-upload"
import Reward from "react-rewards"
import Spinner from "assets/spinner.svg"
import FadeIn from "transitions/fade-in"

async function sendToServer(values) {
  const encode = data => {
    const formData = new FormData()
    Object.keys(data).forEach(k => {
      formData.append(k, data[k])
    })
    return formData
  }

  try {
    const res = await fetch("/", {
      method: "POST",
      body: encode(values),
    })
    return res.status === 200
  } catch (error) {
    console.error(error)
    return false
  }
}

// Autofill Hack - https://codedaily.io/tutorials/69/Animated-Input-Label-with-Chrome-Autofill-Detection-in-React
function handleAutoFill() {}

function ErrorMessage(props) {
  return (
    <div className="c-form__errmsg">
      <div className="c-form__errmsg-inner">{props.error}</div>
    </div>
  )
}

function FieldLabel(props) {
  return (
    <div
      className={`c-form__textlabel ${
        props.isSmall ? "c-form__textlabel--small" : ""
      }`}
    >
      {props.label}
    </div>
  )
}

function MessageField(props) {
  const { getInputProps } = useField("message")
  const val = getInputProps().value
  const hasValue = val && val.length > 0
  return (
    <>
      <FieldLabel isSmall={hasValue} label={props.label} />
      <textarea {...getInputProps(props)} data-small={hasValue} />
    </>
  )
}

function NameField(props) {
  const {
    meta: { error, isSubmitting },
    getInputProps,
  } = useField("name", {
    validate: val => (!val ? props["error-empty"] : false),
  })
  const val = getInputProps().value
  const hasValue = val && val.length > 0

  return (
    <>
      <FieldLabel isSmall={hasValue} label={props.label} />
      <input
        {...getInputProps(props)}
        data-small={hasValue}
        onAnimationStart={handleAutoFill}
      />
      {props["show-errors"] && error ? <ErrorMessage error={error} /> : null}
    </>
  )
}

function EmailField(props) {
  const {
    meta: { error },
    getInputProps,
  } = useField("email", {
    validate: async value => {
      if (!value) {
        return props["error-empty"]
      }

      if (!emailValidator.validate(value)) {
        return props["error-invalid"]
      }
      return false
    },
  })

  const val = getInputProps().value
  const hasValue = val && val.length > 0

  return (
    <>
      <FieldLabel isSmall={hasValue} label={props.label} />
      <input
        {...getInputProps(props)}
        data-small={hasValue}
        onAnimationStart={handleAutoFill}
      />
      {props["show-errors"] && error ? <ErrorMessage error={error} /> : null}
    </>
  )
}

function CompanyField(props) {
  const {
    meta: { error },
    getInputProps,
  } = useField("company", {
    validate: val => (!val ? props["error-empty"] : false),
  })
  const val = getInputProps().value
  const hasValue = val && val.length > 0

  return (
    <>
      <FieldLabel isSmall={hasValue} label={props.label} />
      <input
        {...getInputProps(props)}
        data-small={hasValue}
        onAnimationStart={handleAutoFill}
      />
      {props["show-errors"] && error ? <ErrorMessage error={error} /> : null}
    </>
  )
}

function BotField(props) {
  const { getInputProps } = useField("bot-field")

  return <input {...getInputProps(props)} name="bot-field" />
}

export default function Form({ setFormSent, formText, formData }) {
  // Use the useForm hook to create a form instance
  const [reward, setReward] = useState(null)
  const [showErrors, setShowErrors] = useState(false)

  const {
    Form,
    meta: { canSubmit, isSubmitted, isSubmitting },
  } = useForm({
    onSubmit: async (values, instance) => {
      values["form-name"] = "Contact Form"
      if (file) {
        values["file"] = file
      }

      const res = await sendToServer(values)
      res && reward.rewardMe() // Confetti
      setFormSent(res)
      if (res === true && window.dataLayer !== "undefined") {
        window.dataLayer.push({
          event: "Form Submission",
          formName: "Contact Form",
          category: "New Lead",
          action: "Form Submitted",
          label: "Contact Us",
        })
      }
    },
    debugForm: false,
  })

  const [file, setFile] = useState(null)

  return (
    <section className="container">
      <FadeIn>
        <div
          className="mx-auto mb-8 text-center style-links"
          dangerouslySetInnerHTML={{ __html: formText }}
        />
      </FadeIn>

      <Form
        name="Contact Form"
        data-netlify="true"
        netlify="true"
        netlify-honeypot="bot-field"
        className="max-w-3xl mx-auto md:flex md:flex-wrap c-form c-form--primary c-form--contact"
        encType="multipart/form-data"
        disabled={isSubmitted}
        autoComplete={`no`}
      >
        <label hidden>
          <BotField />
        </label>

        <div className="relative flex-1 md:mr-2">
          <label className="mb-2 c-form__label c-form__label--textarea md:mb-0">
            <MessageField
              className="c-form__field c-form__field--textarea"
              label={formData.message.placeholder}
            />
          </label>

          <div className="absolute c-form__fileupload">
            <FileUpload file={file} setFile={setFile} />
          </div>
        </div>

        <div className="flex flex-col flex-1 md:mr-2">
          <label className="mb-2 c-form__label">
            <NameField
              className="c-form__field c-form__field--input"
              label={formData.name.placeholder}
              show-errors={showErrors ? 1 : 0}
              error-empty={formData.name.errorEmpty}
            />
          </label>

          <label className="mb-2 c-form__label">
            <EmailField
              className="c-form__field c-form__field--input"
              label={formData.email.placeholder}
              show-errors={showErrors ? 1 : 0}
              error-empty={formData.email.errorEmpty}
              error-invalid={formData.email.errorInvalid}
            />
          </label>

          <label className="mb-8 md:mb-2 c-form__label">
            <CompanyField
              className="c-form__field c-form__field--input"
              label={formData.company.placeholder}
              show-errors={showErrors ? 1 : 0}
              error-empty={formData.company.errorEmpty}
            />
          </label>

          <div className="text-center">
            <Reward
              ref={ref => {
                setReward(ref)
              }}
              type="confetti"
              config={{
                spread: 170,
                springAnimation: false,
                angle: 120,
                colors: ["#00122c", "#01cfc9", "#727C89", "#727C89"],
              }}
            >
              <button
                onClick={() => setShowErrors(true)}
                className={`w-full c-form__field c-form__field--submit relative ${
                  isSubmitting ? "is-submitting" : ""
                }`}
                type="submit"
                disabled={isSubmitting || isSubmitted}
              >
                {!isSubmitting ? (
                  <>{!isSubmitted ? "Send" : "Sent"}</>
                ) : (
                  <div className="absolute inset-0 flex flex-col items-center justify-center pointer-events-none">
                    <Spinner />
                  </div>
                )}
              </button>
            </Reward>
          </div>
        </div>
      </Form>
    </section>
  )
}
