import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import SEO from "components/seo"
import Transition from "components/transition"
import ContactHero from "components/contact/hero"
import ContactForm from "components/contact/form"
import GetInTouch from "components/contact/get-in-touch"

const Contact = React.memo(({ data: { wpPage } }) => {
  if (!wpPage) return null
  const { seo, contact } = wpPage

  const [formSent, setFormSent] = useState(false)

  const heroHeading = formSent ? contact.headingMessageSent : contact.heading

  const formText = formSent ? contact.form.textMessageSent : contact.form.text

  // Scroll user to top of page if form sent
  useEffect(() => {
    if (formSent && window.innerWidth < 768) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
      }, 400)
    }
  }, [formSent])

  return (
    <Transition>
      <SEO
        seo={seo}
        bodyAttributes={{
          class: "page--contact",
        }}
      />

      <ContactHero title="Contact" heading={heroHeading} />

      <ContactForm
        formText={formText}
        formData={contact.form}
        setFormSent={setFormSent}
      />

      <GetInTouch />
    </Transition>
  )
})

export default Contact

export const pageQuery = graphql`
  query Contact {
    wpPage(databaseId: { eq: 7627 }) {
      ...SEO
      contact {
        heading: contactHeading
        headingMessageSent
        form {
          text
          textMessageSent
          message {
            placeholder
          }
          name {
            errorEmpty
            placeholder
          }
          email {
            errorEmpty
            errorInvalid
            placeholder
          }
          company {
            errorEmpty
            placeholder
          }
        }
      }
    }
  }
`
