import React from "react"
import Overline from "components/overline"
import FadeIn from "transitions/fade-in"

export default ({ title, heading }) => {
  return (
    <section className="c-hero">
      <div className="container relative justify-center text-center">
        <FadeIn>
          <Overline>{title}</Overline>
          <div className="max-w-xxs xs:max-w-md sm:max-w-xl sm:px-0 c-hero__heading style-links">
            <span dangerouslySetInnerHTML={{ __html: heading }}></span>
          </div>
        </FadeIn>
      </div>
    </section>
  )
}
