import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { toPhoneNumber } from "utils/formatting"
import FadeIn from "transitions/fade-in"

const GetInTouch = () => {
  const data = useStaticQuery(graphql`
    query GetInTouch {
      wpPage(databaseId: { eq: 7627 }) {
        contact {
          getInTouch {
            title
            image {
              ...FluidFullWidthImage
            }
            askAQuestion
            joinTheTeam
            social
            workWithUs
          }
        }
      }

      socialMenu: wpMenu(locations: { eq: SOCIAL }) {
        menuItems {
          nodes {
            url
            label
            id
          }
        }
      }

      wp {
        options {
          company: options {
            companyAddress
            companyPhoneNumber
            emailAddresses {
              generalEmail
              workEmail
              careersEmail
            }
          }
        }
      }
    }
  `)
  const {
    title,
    workWithUs,
    askAQuestion,
    joinTheTeam,
    social,
  } = data.wpPage.contact.getInTouch
  const { company } = data.wp.options
  const socialItems = data.socialMenu.menuItems.nodes.filter(({ label }) =>
    /linkedin|dribbble|instagram/gi.test(label)
  )

  return (
    <section className="text-center bg-white lg:py-28 md:py-24 py-18 contact-bottom md:text-left">
      <div className="get-in-touch-padding">
        <div className="container">
          <div className="max-w-3xl mx-auto md:px-7">
            <FadeIn>
              <div className="flex flex-wrap -ml-12">
                <div className="flex-col justify-between w-full pl-12 md:w-1/3 md:flex">
                  <div>
                    <h3 className="mb-4 font-serif text-2xl leading-snug">
                      {title}
                    </h3>

                    {company.companyAddress && (
                      <a
                        href="https://goo.gl/maps/fd6nWNkExik8hKtj7"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <address
                          className="mb-8 not-italic font-medium leading-relaxed text-17px"
                          dangerouslySetInnerHTML={{
                            __html: company.companyAddress,
                          }}
                        ></address>
                      </a>
                    )}
                  </div>
                  <div>
                    {company.companyPhoneNumber && (
                      <div className="mb-5">
                        <a
                          href={toPhoneNumber(company.companyPhoneNumber)}
                          className="font-medium text-17px text-midnight tg-underline hover:text-accent"
                        >
                          {company.companyPhoneNumber}
                        </a>
                      </div>
                    )}

                    {company.emailAddresses.generalEmail && (
                      <div>
                        <a
                          href="mailto:hello@together.agency"
                          className="font-medium text-17px text-midnight tg-underline hover:text-accent"
                        >
                          {company.emailAddresses.generalEmail}
                        </a>
                      </div>
                    )}
                  </div>
                </div>

                <div className="w-full pl-12 mt-24 mb-6 md:w-2/3 md:my-0">
                  <div className="grid gap-8 row-gap-16 md:gap-16 md:row-gap-22 sm:grid-cols-2">
                    {company.emailAddresses.workEmail && (
                      <div>
                        <h3 className="mb-4 font-serif text-2xl leading-snug">
                          {workWithUs}
                        </h3>
                        <a
                          href={`mailto:${company.emailAddresses.workEmail}`}
                          className="font-medium text-17px text-midnight tg-underline hover:text-accent"
                        >
                          {company.emailAddresses.workEmail}
                        </a>
                      </div>
                    )}

                    {company.emailAddresses.generalEmail && (
                      <div>
                        <h3 className="mb-4 font-serif text-2xl leading-snug">
                          {askAQuestion}
                        </h3>
                        <a
                          href={`mailto:${company.emailAddresses.generalEmail}`}
                          className="font-medium text-17px text-midnight tg-underline hover:text-accent"
                        >
                          {company.emailAddresses.generalEmail}
                        </a>
                      </div>
                    )}

                    {company.emailAddresses.careersEmail && (
                      <div>
                        <h3 className="mb-4 font-serif text-2xl leading-snug">
                          {joinTheTeam}
                        </h3>
                        <a
                          href={`mailto:${company.emailAddresses.careersEmail}`}
                          className="font-medium text-17px text-midnight tg-underline hover:text-accent"
                        >
                          {company.emailAddresses.careersEmail}
                        </a>
                      </div>
                    )}

                    {social && (
                      <div>
                        <h3 className="mb-4 font-serif text-2xl leading-snug">
                          {social}
                        </h3>
                        <div className="flex justify-center -ml-3 md:justify-start">
                          {socialItems.map((item, i) => (
                            <div
                              className="pl-3"
                              key={`contact-social-${item.id}`}
                            >
                              <a
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={
                                  "tg-underline text-midnight hover:text-accent"
                                }
                              >
                                {item.label +
                                  (i === socialItems.length - 1 ? "" : ", ")}
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </FadeIn>
          </div>
        </div>
      </div>
    </section>
  )
}

export default GetInTouch
